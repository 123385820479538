import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import Headline from "../components/Headline";

export const query = graphql`
{
  allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
    
  }
  }
  }
 `

const AGBs = ({data}) => {
  return (
    <Layout>
        <div className="basepage">
            <SEO title={"AGBs | Matthey & Melchior"}/>

            <div className={"headline__container"} >
                <h1 className={"center minion"}>AGBs</h1>
                <div className="separator title"></div>
            </div>
            {data.allSanityAgbs.edges[0].node.agbfile.asset.url ? <div className="btn--center">
                <a className="btn fit" href={data.allSanityAgbs.edges[0].node.agbfile.asset.url + "?dl="} >Allgemeine Geschäftsbedingungen</a>
            </div> : <></>}
        </div>
    </Layout>
  )
}

export default AGBs
